import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    iconInfo: {
        marginLeft: theme.spacing(0.2),
        display: 'inline',
        '& svg': {
            fill: theme.palette.primary.main,
            width: 20,
            height:20,
        }
    }
}))