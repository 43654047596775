import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './form-contrato-funcionario-styles';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
import { ContratoModuloModel } from 'model/api/gestao/contrato/contrato-modulo-model';
import { ContratoFormEdicaoModel } from 'model/app/forms/contrato/contrato-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EnumTipoCalculo, KeyValueModel } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { calcPercent } from 'utils/calc-percent';
import { toDecimal } from 'utils/to-decimal';
import { PrecoModulos, TabelasSistema } from 'model/api/gestao/sistemas/sistema-tabela-precos-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { CardModuloContratoFuncionario } from 'views/components/cards/card-modulos-contrato-visualizar/card-modulos-contrato-funcionario';
import { guidEmpty } from 'utils/guid-empty';
import { toDateString, toDateStringApi } from 'utils/to-date';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { FilialIcon } from 'views/components/icons/filial-icon';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { IdsModel } from '../contrato-edicao/form-contrato-editar';

interface Props extends DefaultFormProps<ContratoFormEdicaoModel> {
  openDialog: () => void;
  dataCancelamento?: string;
  status: boolean;
  tipoCobranca: EnumTipoCobrancaRevenda;
  motivoCancelamento?: string;
  refContratoModel: React.MutableRefObject<ContratoModel>;
}

export const FormContratoFuncionario = React.forwardRef<
  DefaultFormRefs<ContratoFormEdicaoModel>,
  Props
>((props: Props, refs) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { tipoUsuario } = useSessaoAtual()
  const [precosModulosCard, setPrecosModulosCard] = useState<
    ContratoModuloModel[]
  >([]);
  const [model, setModel] = useState<ContratoFormEdicaoModel>(
    new ContratoFormEdicaoModel(),
  );

  const isTipoRevenda = (props.tipoCobranca === EnumTipoCobrancaRevenda.Revenda || props.tipoCobranca === EnumTipoCobrancaRevenda.RevendaPagas)

  const { isMobile, theme } = useThemeQueries();

  const [planoId, setPlanoId] = useState<string | null>('');


  const [tabelasPreco, setTabelasPreco] = useState<TabelasSistema[]>([]);
  const [tabelaPreco, setTabelaPreco] = useState<TabelasSistema>(
    new TabelasSistema(),
  );
  const tabelaPrecoOriginal = useRef<TabelasSistema>(new TabelasSistema());

  const [ambienteMock, setAmbienteMock] = useState<KeyValueModel[]>([])
  const [pagamentoMock, setPagamentoMock] = useState<KeyValueModel[]>([])

  const idsOriginais = useRef<IdsModel[]>([])


  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue,
  } = useForm<ContratoFormEdicaoModel>({
    defaultValues: { ...model },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const carregando = props.loading;

  const isRetaguarda = getValues('sistemaId') === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9'

  const onSubmit = (form: ContratoFormEdicaoModel) => {
    form.modulos = [...precosModulosCard];
    form.valorContrato = toDecimal(form.valorContrato, 4)
    form.valorCalculado = toDecimal(form.valorCalculado, 4)

    if (form.modulos.find(modulo => modulo.quantidadeContratada > modulo.quantidadeMaxima ||
      modulo.quantidadeContratada < modulo.quantidadeMinima)) {
      showToast('error', 'Módulos com quantidade incorretas.')
      return
    }

    if (form.dataExpiracao) {
      form.dataExpiracao = toDateStringApi(new Date(form.dataExpiracao))
    }

    props.onSubmit(form, model);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...model });
      setPrecosModulosCard(model.modulos);
    },
    fillForm: (model: ContratoFormEdicaoModel) => {
      if (model.dataExpiracao) {
        model.dataExpiracao = model.dataExpiracao.split('T')[0]
      }
      setModel({ ...model });
      setPlanoId(model.planoId);
      setPrecosModulosCard(model.modulos);

      const precosModulosOriginais = model.modulos.map(modulo => {
        const mod = new ModuloModel(modulo.id, modulo.nome, modulo.tipoCalculo,
          modulo.codModuloBaseCalculo, modulo.codigo, '', modulo.quantidadeMinima,
          modulo.quantidadeMaxima, modulo.quantidadePadrao, -1 as EnumTipoControleModulo, '')
        return new PrecoModulos(modulo.id, modulo.valorOriginal, modulo.valorCustoOriginal,
          modulo.percAdicional, modulo.valorCusto, modulo.percAdicionalCusto, mod)
      })
      tabelaPrecoOriginal.current = new TabelasSistema(model.tabelaPrecoId, model.tabelaPreco, precosModulosOriginais)

      idsOriginais.current = model.modulos.map(mod => ({
        moduloId: mod.moduloId,
        id: mod.id,
        quantidadeContratada: mod.quantidadeContratada
      }))

      //setando valores que vem do dadosTela

      setAmbienteMock(model.dadosTela.ambientes.map(ambiente => new KeyValueModel(ambiente.id, ambiente.nome)))
      setPagamentoMock(model.dadosTela.pagamentos.map(pagamento => new KeyValueModel(pagamento.id, pagamento.nome)))

      setTabelaPreco(!isEmpty(model.dadosTela.precos.tabelas) ? model.dadosTela.precos.tabelas.find(element => element.tabelaPrecoId === model.tabelaPrecoId) || model.dadosTela.precos.tabelas[0] : new TabelasSistema())
      setTabelasPreco(model.dadosTela.precos.tabelas)
      setValue('tabelaPrecoId', !isEmpty(model.dadosTela.precos.tabelas) ? model.dadosTela.precos.tabelas[0].tabelaPrecoId : '')

      reset({ ...model });
    },
  }));

  // const quantidadeDias = () => {
  //   let diaMax = 31;
  //   let dias: Array<number> = [];

  //   for (let i = 1; i <= diaMax; i++) {
  //     dias = [...dias, i];
  //   }
  //   return dias.map((item) => new KeyValueModel(item, item.toString()));
  // };

  const valoresFinais = precosModulosCard.map((item) => {
    let quantMult = item.quantidadeContratada;
    let quantMult2 = item.quantidadeContratada;
    let valorFinal = item.valorOriginal;
    let valorCusto = item.valorCusto;

    for (let i = 1; i <= item.quantidadeContratada; i++) {
      if (item.quantidadeContratada > 1) {
        quantMult =
          (item.quantidadeContratada - 1) *
          calcPercent(item.valorOriginal, isTipoRevenda ? item.percAdicionalCusto : item.percAdicional);
        quantMult2 =
          (item.quantidadeContratada - 1) *
          calcPercent(item.valorCusto, item.percAdicionalCusto);
      }
    }

    if (item.quantidadeContratada === 0) {
      valorFinal = 0;
      valorCusto = 0;
    } else if (item.quantidadeContratada < 2) {
      valorFinal = item.valorOriginal;
      valorCusto = item.valorCusto;
    } else {
      valorFinal = valorFinal + quantMult;
      valorCusto = valorCusto + quantMult2;
    }

    if (item.tipoCalculo === EnumTipoCalculo.Modulo && item.tipoControle === EnumTipoControleModulo.Filial) {
      const codsBase = item.codModuloBaseCalculo.includes(',') ? item.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : item.codModuloBaseCalculo.split('-').map(cod => Number(cod))
      const moduloBase = precosModulosCard.filter(mod => codsBase.includes(mod.codigo))

      const quantidadeContratadaBase = moduloBase.reduce((prev, curr) => curr.quantidadeContratada + prev, 0)

      valorFinal = moduloBase ? valorFinal * (quantidadeContratadaBase || 1) : valorFinal
      valorCusto = moduloBase ? valorCusto * (quantidadeContratadaBase || 1) : valorCusto
    } else if (item.tipoCalculo === EnumTipoCalculo.Modulo) {
      const codBase = Number(item.codModuloBaseCalculo)
      const moduloBase = precosModulosCard.find(mod => mod.codigo === codBase)

      valorFinal = moduloBase ? valorFinal * (moduloBase?.quantidadeContratada || 1) : valorFinal
      valorCusto = moduloBase ? valorCusto * (moduloBase?.quantidadeContratada || 1) : valorCusto
    }

    return {
      ...item,
      valorFinal: valorFinal,
      valorCusto: valorCusto,
    };
  });

  const alterarQtdModulo = useCallback(
    (model: ContratoModuloModel) => {
      const modulo = [...precosModulosCard];
      const ret = modulo.find((item) => item.moduloId === model.moduloId);

      const index = modulo.indexOf(ret || new ContratoModuloModel());
      modulo[index] = model;
      setPrecosModulosCard(modulo);
    },
    [precosModulosCard],
  );

  const alterarQtd = useCallback(
    (model: ContratoModuloModel, qtde: number) => {
      const newState = Object.assign({}, model);

      newState.quantidadeContratada = qtde;
      alterarQtdModulo(newState);
    },
    [alterarQtdModulo],
  );

  useEffect(() => {
    // try catch pra retirar o erro que não estava entendendo o que estava acontecendo
    try {
      setValue(
        'valorCalculado',
        valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0),
      );

      setValue(
        'valorCusto',
        valoresFinais.reduce((a: any, b: any) => a + b.valorCusto, 0),
      );

      setValue(
        'valorContrato',
        valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0) -
        toDecimal(getValues('valorDesconto'), 4),
      );
    } catch { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precosModulosCard]);

  useEffect(() => {
    if (!isEmpty(tabelaPreco) && !isEmpty(tabelaPreco.precoModulos)) {

      setPrecosModulosCard((prev) => {
        return tabelaPreco.precoModulos.map(
          (item, index) => {
            let isValueEqual = true;
            let prevValue: number | undefined = 0;
            if (tabelaPrecoOriginal.current.tabelaPrecoId === tabelaPreco.tabelaPrecoId) {
              prevValue = tabelaPrecoOriginal.current.precoModulos.find(x => x.modulo.codigo === item.modulo.codigo)?.valor;
              if (prevValue !== item.valor) {
                isValueEqual = false;
              }
            }
            return new ContratoModuloModel(
              idsOriginais.current.find(x => x.moduloId === item.modulo.id)?.id,
              item.modulo.codigo,
              item.modulo.nome,
              item.modulo.descricao,
              item.valor,
              item.valorCustoOriginal,
              idsOriginais.current.find(x => x.moduloId === item.modulo.id)?.quantidadeContratada,
              item.valor,
              item.percAdicional,
              item.modulo.id,
              model.id,
              item.modulo.quantidadeMaxima,
              item.modulo.quantidadeMinima,
              item.modulo.quantidadePadrao,
              item.valorCusto,
              item.percAdicionalCusto,
              item.modulo.codModuloBaseCalculo,
              item.modulo.tipoCalculo,
              item.modulo.tipoControle,
              !isValueEqual,
              prevValue
            )
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, model.id, setValue, showToast, tabelaPreco]);

  //Memos para validar exibição dos campos (decidi criar um memo para organizar e validar as grids dos campos, porque os ternários estavam poluindo mt o código)

  const camposSuperiores = useMemo(() => {

    let campos = <></>;

    const gridDominio = !isTipoRevenda || ambienteMock.length > 0 ? 6 : 12
    const gridFormaPagamento = isRetaguarda || ambienteMock.length > 0 ? 6 : 12
    const gridAmbiente = !isTipoRevenda && isRetaguarda ? 12 : 6

    if (isRetaguarda) {
      campos = <>
        {campos}
        <Grid item lg={gridDominio} xs={12}>
          <TextFieldSaurus
            value={getValues('dominio')}
            readOnly
            size='small'
            label='Domínio'
          />
        </Grid>

      </>
    }

    if (!isTipoRevenda) {
      campos = <>
        {campos}
        <Grid item lg={gridFormaPagamento} xs={12}>
          <Controller
            name="formaPagamentoId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                label="Forma de Pagamento"
                conteudo={pagamentoMock}
                disabled
                size='small'
                helperText={
                  errors.formaPagamentoId
                    ? errors.formaPagamentoId.message
                    : undefined
                }
                error={Boolean(
                  errors.formaPagamentoId && errors.formaPagamentoId.message,
                )}
                {...field}
                onChange={(ev) => {
                  const item = pagamentoMock.filter(
                    (item) => item.Key === ev.target.value,
                  );

                  setValue('formaPagamentoId', item[0].Key);
                }}
              />
            )}
          />
        </Grid>
      </>
    }

    if (ambienteMock.length > 0) {
      campos = <>
        {campos}
        <Grid item lg={gridAmbiente} xs={12}>
          <Controller
            name="ambienteId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                conteudo={ambienteMock}
                disabled
                fullWidth
                variant="outlined"
                label="Ambiente"
                size='small'
                {...field}
                onChange={(event) => {
                  if (event) {
                    const item = ambienteMock.filter(
                      (item) =>
                        item.Key === event.target.value,
                    )[0];
                    if (item) {
                      setValue('ambienteId', item.Key);
                      return
                    }
                    setValue('ambienteId', '')
                  }
                }}
                helperText={
                  errors.ambienteId
                    ? errors.ambienteId.message
                    : undefined
                }
                error={Boolean(
                  errors.ambienteId && errors.ambienteId.message,
                )}
              />
            )}
          />
        </Grid>
      </>
    }
    return campos
  }, [ambienteMock, control, errors.ambienteId, errors.formaPagamentoId, getValues, isRetaguarda, isTipoRevenda, pagamentoMock, setValue])

  //--------------------------------

  const showFiliais = useMemo(() => {
    return props.refContratoModel.current.modulos.find(mod => mod.tipoControle === EnumTipoControleModulo.Filial && mod.quantidadeContratada > 0) ||
      tipoUsuario() === EnumTipoPessoas.SoftwareHouse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={utilClasses.formContainer}
        style={{
          height: '100%',
        }}
      >
        {carregando || props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: !isMobile ? '1rem' : '10px',
            background: '#fff'
          }}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={3} style={{ flexDirection: 'row-reverse' }}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="caption">Sistema</Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{ fontWeight: 600 }}
                  >
                    {model.sistemaNome}
                  </Typography>
                </Grid>
                {showFiliais && <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: isMobile ? 'flex-end' : 'flex-start', height: '100%', alignSelf: 'stretch' }}>
                  <Button color='primary' variant='outlined' onClick={props.openDialog} fullWidth>
                    <FilialIcon tipo='BUTTON' />
                    {isMobile ? 'Empresas' : 'Empresas Vinculadas'}
                  </Button>
                </Grid>}
                <Grid item xs={12} md={6}>
                  <>
                    <Typography variant="caption">Plano</Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{ fontWeight: 600, marginBottom: '15px' }}
                    >
                      {(model.plano && model.plano.nome) ? model.plano.nome : 'Não há um plano'}
                    </Typography>
                  </>
                </Grid>
                <Grid item xs={12} md={6}>
                  {(Array.isArray(tabelasPreco) && tipoUsuario() === EnumTipoPessoas.Revendedor) ? (
                    <Controller
                      name="tabelaPrecoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={tabelasPreco.map(
                            (item) =>
                              new KeyValueModel(item.tabelaPrecoId, item.nome),
                          )}
                          fullWidth
                          variant="outlined"
                          label="Tabela de Preços"
                          size='small'
                          disabled
                          {...field}
                          value={getValues('tabelaPrecoId')}
                          onChange={(event) => {
                            if (event) {
                              const item = tabelasPreco.filter(
                                (item) =>
                                  item.tabelaPrecoId === event.target.value,
                              )[0];
                              if (item) {
                                setTabelaPreco(item);
                                setValue('tabelaPrecoId', item.tabelaPrecoId);
                              }
                            }
                          }}
                          helperText={
                            touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                              ? errors.tabelaPrecoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                          )}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Typography variant="caption">Tabela de Preços</Typography>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ fontWeight: 600, marginBottom: '15px' }}
                      >
                        {model.tabelaPreco}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                {camposSuperiores}
                {!isEmpty(getValues('dataExpiracao')) && <Grid item xs={12}>
                  <Controller
                    name="dataExpiracao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Expiração"
                        size="small"
                        tipo="DATA"
                        manterMascara
                        disabled
                        helperText={
                          touchedFields.dataExpiracao && errors.dataExpiracao
                            ? errors.dataExpiracao.message
                            : undefined
                        }
                        error={Boolean(
                          errors.dataExpiracao && errors.dataExpiracao.message,
                        )}
                        {...field}
                      />
                    )}
                  />
                </Grid>}
                {(!isEmpty(props.dataCancelamento) && !props.status) && <>
                  <Grid item xs={12}>
                    <Divider style={{ background: theme.palette.divider }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '0.75rem' }} color='textSecondary'>Data de Cancelamento</Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      {toDateString(props.dataCancelamento + 'Z', 'yyyy-MM-DD HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '0.75rem' }} color='textSecondary'>Motivo do Cancelamento</Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{ fontWeight: 500 }}
                    >
                      {props.motivoCancelamento}
                    </Typography>
                  </Grid>
                </>}
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid
                container
                spacing={1}
                style={{
                  flex: '1 1 auto',
                  position: 'relative',
                }}
                className={classes.modulosContainer}
              >
                <Grid item xs={12}>
                  <Typography variant='h6' color='textPrimary'>Módulos</Typography>
                </Grid>
                <Grid item xs={12} className={classes.modulosScroll}>
                  {precosModulosCard.sort((a, b) => a.codigo - b.codigo).filter(item => item.tipoControle !== EnumTipoControleModulo.Filial).map((item, index) => {
                    return (
                      <CardModuloContratoFuncionario
                        hasPlanoId={
                          !isEmpty(planoId || planoId !== guidEmpty())
                        }
                        alterarQtd={alterarQtd}
                        model={item}
                        modulos={precosModulosCard}

                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
